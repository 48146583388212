import React, { useState, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import AppRouter from './AppRouter';
import About from './views/about/About';
import NotFound from './views/notFound/NotFound';
import OffsetCertificates from './views/offsetCertificates/OffsetCertificates';
import { GET_CURRENT_USER } from './api/apollo/api';
import { DraftContext } from './context';
import ExternalLink from './components/externalLink/ExternalLink';
import Logout from './views/Logout';
import SupportChat from './modules/tt-concierge';

const { REACT_APP_CONCIERGE_URL, REACT_APP_CONCIERGE_SECRETS } = process.env;

const App = () => {
  const [getUser] = useLazyQuery(GET_CURRENT_USER);
  const { changeUserData } = useContext(DraftContext);
  const [showSupportChat, setShowSupportChat] = useState(false);

  const laSalleGreenTheme = {
    headerGradient: 'linear-gradient(99.65deg, #143471 100%, #1550BD 100%)',
    sendButtonColor: '#143471',
    messageBackgroundColor: '#E3EDFF',
    supportButton: '#143471',
  };

  useEffect(() => {
    getUser().then((res) => {
      if (res?.data?.currentUser?.id) {
        changeUserData({ ...res.data.currentUser });
      } else {
        window.location = process.env.REACT_APP_SSO_SIGN_IN;
      }
    });
  }, []);

  return (
    <>
      <SupportChat
        them={laSalleGreenTheme}
        showSupportChat={showSupportChat}
        setShowSupportChat={setShowSupportChat}
        companyName="TT Concierge"
        apiUrl={REACT_APP_CONCIERGE_URL} // API url
        apiSecret={REACT_APP_CONCIERGE_SECRETS} // API Authorization token
        supportMail="support@transparenterra.com"
        user=""
      />
      <Routes>
        <Route exact path="/login" element={<ExternalLink />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/offset-credits" element={<OffsetCertificates />} />

        <Route exact path="/drafts" element={<ExternalLink />} />
        <Route exact path="/profile/:param" element={<ExternalLink />} />
        <Route exact path="/completed" element={<ExternalLink />} />
        <Route exact path="/buy_credits/:param" element={<ExternalLink />} />
        <Route exact path="/newassessment" element={<ExternalLink />} />
        <Route exact path="/carbon_credits" element={<ExternalLink />} />
        <Route exact path="/dashboard" element={<ExternalLink />} />

        <Route exact path="/" element={<ExternalLink />} />
        <Route exact path="/*" element={<AppRouter />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
