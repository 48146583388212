import { API_CALC_URL } from '../../urls';

export const API_CALC_GET_DRAFT_BY_ID = `${API_CALC_URL}/calculator/draft/get`;
export const API_CALC_CREATE_TEMPLATE = `${API_CALC_URL}/calculator/draft/new`;
export const API_CALC_UPDATE_DRAFT = `${API_CALC_URL}/calculator/draft/upd`;
export const API_CALC_REPLACE_DRAFT = `${API_CALC_URL}/calculator/draft/replace`;
export const API_CALC_GET_DRAFTS_LIST = `${API_CALC_URL}/calculator/drafts/get`;
export const API_CALC_COMPLETE_DRAFT = `${API_CALC_URL}/calculator/drafts/complite`;

export const API_CALC_GET_ANSWERS = `${API_CALC_URL}/calculator/answers/get`;
export const API_CALC_UPDATE_ANSWERS = `${API_CALC_URL}/calculator/answers/upd`;
export const API_CALC_PRELIMINARY = `${API_CALC_URL}/calculator/draft/preliminary`;
