import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const AddButton = ({ text, className, onClick, isDisabled }) => (
  <Button
    className={className}
    variant="outlined"
    onClick={onClick}
    startIcon={<AddCircleOutlineIcon />}
    disabled={isDisabled}
  >
    {text}
  </Button>
);

AddButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

AddButton.defaultProps = {
  text: '',
  className: '',
  onClick: () => {},
  isDisabled: false,
};

export default AddButton;
