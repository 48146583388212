import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Grid, Button } from '@mui/material';

import { CalculatorsContext, DraftContext } from '../../../context';
import { UPDATE_DRAFT } from '../../../api/apollo/api';

import Form from '../../../components/form/Form';
import Pagination from '../../../components/pagination/Pagination';
import BoolQuestion from './BoolQuestion';
import InputQuestion from './InputQuestion';

import styles from './index.module.scss';
import QuestionButton from '../../../components/questionButton/QuestionButton';
import CalculationCost from '../../../components/calculationCost/CalculationCost';
import { completeDraft } from '../../../api/rest/list';

const Local = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [updateDraft] = useMutation(UPDATE_DRAFT);

  const { draftId } = useContext(DraftContext);
  const {
    chartData,
    questions,
    calculatorsList,
    setCalculatorsList,
    calculatorAnswers,
    changeCalculatorAnswer,
    isAnyAnswerExists,
  } = useContext(CalculatorsContext);

  const [index, setIndex] = useState(-1);
  const [answer, setAnswer] = useState(null);

  const setNewAnswer = (newAnswer) => {
    if (newAnswer || newAnswer === 0) setAnswer(newAnswer);
  };

  useEffect(() => {
    if (questions.length === 0) return;
    const newIndex = parseInt(pathname.replace(/^.*[\\/]/, ''), 10) - 1;
    const { code } = questions[newIndex];
    const lAnswer = calculatorAnswers[code] ?? null;
    setIndex(newIndex);
    setAnswer(lAnswer);
  }, [pathname]);

  useEffect(() => {
    if (!questions || !questions[index] || !(answer === 0 || answer)) return;
    changeCalculatorAnswer(questions[index].code, answer);
  }, [answer]);

  const onSaveAnswer = async () => {
    if (draftId && (answer === 0 || answer)) {
      changeCalculatorAnswer(questions[index].code, answer);
      try {
        await updateDraft({
          variables: {
            input: {
              id: draftId,
              answers: JSON.stringify({
                ...calculatorAnswers,
                [questions[index].code]: answer,
              }),
              last_position: JSON.stringify(pathname),
              state_graphics: JSON.stringify(chartData),
            },
          },
        });
      } catch (_) {
        // TODO: показывать ошибку, если запрос не прошёл
        // сейчас ошибки игнорируются и происходит переход дальше
        // ответы сохраняются локально и будут сохранены в следующих запросах
        // проблема будет только если все запросы вернут ошибки — прохождение не сохранится
      }
    }
  };

  const onBackClick = async () => {
    await onSaveAnswer();

    if (index === 0) {
      const calcIndex = calculatorsList.findIndex((calculator) => calculator.name === 'local');

      if (calcIndex < 0) {
        setCalculatorsList([]);
        navigate('/');
      } else if (calcIndex === 0) {
        navigate('/drafts');
      } else {
        navigate(calculatorsList[calcIndex - 1].url, {
          state: { back: true },
        });
      }
    } else {
      navigate(`/calculators/local/${index}`);
    }
  };

  const onBackToListOfIssues = () => {
    navigate('/drafts');
  };

  const getQuestionButtonText = () => {
    if (questions[index].type === 'bool') {
      return answer === 1 ? 'yes' : 'no';
    }
    return answer;
  };

  const onNextClick = async () => {
    await onSaveAnswer();

    if (index === questions.length - 1) {
      const calcIndex = calculatorsList.findIndex((calculator) => calculator.name === 'local');

      if (calcIndex < 0) {
        setCalculatorsList([]);
        navigate('/');
      } else if (calcIndex === calculatorsList.length - 1) {
        if (isAnyAnswerExists()) {
          await completeDraft();
          navigate(`/buy_credits/${draftId}`);
        } else navigate('/drafts');
      } else {
        navigate(calculatorsList[calcIndex + 1].url);
      }
    } else {
      navigate(`/calculators/local/${index + 2}`);
    }
  };

  const getButtonName = () => {
    const calcIndex = calculatorsList.findIndex((calculator) => calculator.name === 'local');
    return calcIndex === calculatorsList.length - 1 && index === questions.length - 1
      ? 'FINISH'
      : 'NEXT';
  };

  if (!questions[index]) {
    return <div />;
  }

  const currentPages = questions
    .filter(
      (question) =>
        question.categories[question.categories.length - 1] ===
        questions[index].categories[questions[index].categories.length - 1]
    )
    .map(({ code }) => code);

  return (
    <>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        className={styles.formContainer}
      >
        <div>
          <div>
            <QuestionButton
              isAnsvered={answer != null}
              type="isAnsvered"
              label={answer != null ? `Your answer: ${getQuestionButtonText()}` : 'Answer required'}
            />
            <QuestionButton
              action={onBackToListOfIssues}
              type="redirect"
              label="Back to list of issues"
            />
            <QuestionButton action={updateDraft} type="save" label="Save & complete later" />
          </div>
          <Form headerContent={questions[index].title}>
            {questions[index].type === 'bool' && (
              <BoolQuestion
                text={questions[index].body}
                answer={answer}
                setAnswer={setNewAnswer}
                fileCode={questions[index]?.code}
              />
            )}
            {questions[index].type === 'number' && (
              <InputQuestion
                text={questions[index].body}
                answer={answer}
                setAnswer={setNewAnswer}
                fileCode={questions[index]?.code}
              />
            )}
          </Form>
          <CalculationCost />
        </div>
        <div className={styles.navigation}>
          <Button variant="outlined" onClick={onBackClick}>
            BACK
          </Button>

          <Pagination
            activePage={currentPages.indexOf(questions[index].code) + 1}
            pagesCount={currentPages.length}
          />

          <Button variant="contained" onClick={onNextClick}>
            {getButtonName()}
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default Local;
