import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CalculatorsContext, DraftContext } from '../../context';

const EditAssessment = () => {
  const navigate = useNavigate();
  const { draftId } = useParams();

  const { calculatorsList } = useContext(CalculatorsContext);
  const { setDraftId, draft } = useContext(DraftContext);

  useEffect(() => {
    window.sessionStorage.removeItem('SELECTED_DRAFT_ID');
    setDraftId(null);
  }, []);

  useEffect(() => {
    if (!draftId) return;
    setDraftId(draftId);
  }, [draftId]);

  useEffect(() => {
    if (!calculatorsList || !calculatorsList.length || !draft) return;
    if (draft?.lastPosition) navigate(draft.lastPosition);
    else navigate('/registration', { replace: true });
  }, [calculatorsList, draft]);

  return <></>;
};

export default EditAssessment;
