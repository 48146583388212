/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import { CalculatorsContext, DraftContext, ErrorContext } from '../../../context';
import { CALC_TYPES, CalculatorsListData, ChartSectionsMappings } from '../../../constants';

// import NotFound from "../../../views/notFound/NotFound";

import LocalCalculator from '../Local/Local';
import CarbonCalculator from '../Carbon/Carbon';
import GlobalCalculator from '../Global/Global';

import Chart from '../../../components/chart/Chart';
import ProgressCircle from '../../../components/progressCircle/ProgressCircle';
import AccordionGroup from '../../../components/accordionGroup/AccordionGroup';
import SelfAssessment from '../../../components/selfAssessment/SelfAssessment';
import SolutionsGlobalModal from '../../../components/solutionsModal/SolutionsGlobalModal.jsx';
import SolutionsModal from '../../../components/solutionsModal/SolutionsModal';
import { ColorScheme } from '../../../constants';

import styles from './CalculatorsRouter.module.scss';

import { getCalcAnswers, getCalcQuestions } from '../../../api/rest/list.js';

const CalculatorsRouter = () => {
  const navigate = useNavigate();
  /* TODO сделать переход на последний вопрос */
  // const { pathname, state } = useLocation();
  const { pathname } = useLocation();
  const calculatorContainer = useRef();
  const {
    carbonQuestions,
    localQuestions,
    globalQuestions,
    lastVisitedIndex,
    calculatorsList,
    carbonAnswers,
    calculatorName,
    setChartData,
    section,
    setQuestionsData,
    setAnswersData,
    questionsMap,
    setQuestionsMap,
  } = useContext(CalculatorsContext);
  const { draft } = useContext(DraftContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const [previousCount, setPreviousCount] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionCount, setQuestionCount] = useState(1);
  // const [chartIndex, setChartIndex] = useState(null);
  const [chartColor, setChartColor] = useState('#12491D');
  const [chartName, setChartName] = useState(null);
  const [chartPartData, setChartPartData] = useState([]);

  const [accordionItems, setAccordionItems] = useState([]);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activeSector, setActiveSector] = useState(null);

  const openModal = (activeItem) => {
    setActiveSector(activeItem);
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setActiveSector(null);
    setIsModalOpened(false);
  };

  useEffect(() => {
    if (!carbonQuestions) return;
    const createCumulativeMaps = () => {
      const scopeMap = [];
      const categoryMap = [];
      let cumulativeQuestionCount = 0;

      carbonQuestions.forEach((scope) => {
        const categoriesInScope = [];
        const scopeStart = cumulativeQuestionCount;
        scope.categories.forEach((category) => {
          const questionCount = category.questions.length;
          const start = cumulativeQuestionCount;
          cumulativeQuestionCount += questionCount;
          categoriesInScope.push({ start, end: cumulativeQuestionCount - 1 });
        });

        // After processing all categories, store the cumulative count in scopeMap
        scopeMap.push({ start: scopeStart, end: cumulativeQuestionCount - 1 });
        categoryMap.push(categoriesInScope);
      });

      return { scopeMap, categoryMap };
    };
    const { scopeMap, categoryMap } = createCumulativeMaps();
    setQuestionsMap({ carbon: { scopeMap, categoryMap } });
  }, [carbonQuestions]);

  useEffect(() => {}, [localQuestions]);

  useEffect(() => {}, [globalQuestions]);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        // Run all requests sequentially and update state safely
        const requests = calculatorsList.map(async (model) => {
          const param = { model, calculator_id: draft.id };
          const res = await getCalcAnswers(param); // Call API
          const { data } = res.data;
          return { [model]: JSON.parse(data?.[model] ?? '{}') }; // Return the result in an object
        });

        // Wait for all requests to complete
        const results = await Promise.all(requests);

        // Merge all results into a single object
        const mergedAnswers = results.reduce((acc, current) => {
          return { ...acc, ...current };
        }, {});

        // Update the context state with the final merged object
        setAnswersData(mergedAnswers);
      } catch (error) {
        setErrorAlert('Failed to load answers:', error);
      }
    };

    if (!calculatorsList?.length || !draft) return;
    fetchAnswers();
  }, [calculatorsList, draft]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // Run all requests sequentially and update state safely
        const requests = calculatorsList.map(async (model) => {
          const param = { model };
          if (model === 'carbon') param.collection_id = draft.ukGhgCollectionId;
          const res = await getCalcQuestions(param); // Call API
          const { data } = res.data;
          return { ...data }; // Return the result in an object
        });

        // Wait for all requests to complete
        const results = await Promise.all(requests);

        // Merge all results into a single object
        const mergedQuestions = results.reduce((acc, current) => {
          return { ...acc, ...current };
        }, {});

        // Update the context state with the final merged object
        setQuestionsData(mergedQuestions);
      } catch (error) {
        setErrorAlert('Failed to load questions:', error);
      }
    };

    if (!calculatorsList?.length || !draft) return;
    fetchQuestions();
  }, [calculatorsList, draft]);

  // update chart data with carbon answers
  useEffect(() => {
    if (!carbonQuestions || !carbonAnswers) return;
    const chartData = carbonQuestions.reduce((acc, scope) => {
      const children = scope.categories.reduce(
        (acc2, category) => [
          ...acc2,
          {
            name: category.name,
            type: 'category',
            color: ColorScheme[scope.code],
            value: category.questions.filter((q) =>
              carbonAnswers[category.code]?.[q.id]?.some((a) => a.filled)
            ).length,
            total: category.questions.length,
            code: category.code,
          },
        ],
        []
      );
      return [
        ...acc,
        {
          name: scope.name,
          type: 'scope',
          color: ColorScheme[scope.code],
          value: children.filter((c) => c.value > 0).length,
          total: scope.categories.length,
          code: scope.code,
          children,
        },
      ];
    }, []);
    setChartData(chartData);
  }, [carbonQuestions, carbonAnswers]);

  // useEffect(() => {
  //   const availableCalculators = CalculatorsListData[calculatorName].children;
  //   if (availableCalculators && availableCalculators.length) {
  //     const isExists = availableCalculators.some((url) => pathname.includes(url));
  //     if (!isExists) navigate('/drafts');
  //   }
  // }, [calculatorName, pathname]);

  // useEffect(() => {
  //   if (calculatorName === CALC_TYPES.GLOBAL) return;

  //   const isESG = pathname.includes('/local/');
  //   if (!isESG) {
  //     const descriptions = getCalculatorDescription({ pathname });
  //     setAccordionItems(descriptions);
  //   } else if (isESG && questions && questions.length) {
  //     const newIndex = parseInt(pathname.replace(/^.*[\\/]/, ''), 10) - 1;
  //     const question = questions[newIndex];
  //     const descriptions = getCalculatorDescription({ pathname, question });

  //     setQuestionIndex(newIndex);
  //     setAccordionItems(descriptions);
  //   }
  // }, [questions, pathname]);

  // useEffect(() => {
  //   if (!calculatorName || calculatorName === 'global' || !companyQuestions) return;
  //   const calculators = calculatorsList.map((c) => c.name);
  //   const companyCalc = calculators.includes('scope3')
  //     ? companyQuestions.reduce((res, q) => res + q.questions.length, 0)
  //     : 0;

  //   const localCalc = calculators.includes('local') ? questions.length : 0;

  //   const currentIndex = calculators.indexOf(calculators.find((c) => pathname.includes(c)));

  //   setPreviousCount(0);
  //   if (calculators.includes('scope3') && calculators.indexOf('scope3') < currentIndex) {
  //     setPreviousCount((value) => value + companyCalc);
  //   }
  //   if (calculators.includes('local') && calculators.indexOf('local') < currentIndex) {
  //     setPreviousCount((value) => value + localCalc);
  //   }
  //   setQuestionCount(companyCalc + localCalc);
  // }, [carbonSources, questions, questionIndex]);

  const onAccordionSelect = (selected, name) => {
    const items = [...accordionItems];
    const oldActive = items.findIndex((v) => v.expanded);

    if (oldActive !== -1) items[oldActive].expanded = false;

    if (selected) {
      const newActive = items.findIndex((v) => v.name === name);

      if (newActive !== -1) items[newActive].expanded = true;
    }
    setAccordionItems(items);
  };

  // useEffect(() => {
  //   if (!section) return;
  //   const data = {};
  //   // if (chartData.some((c) => section === c.type)) {
  //   //   if (pathname.includes('global')) {
  //   //     const reg = /\/global\/(\d+)/;
  //   //     const match = pathname.match(reg);
  //   //     data.chart = chartData[match[1] - 1].children;
  //   //   } else {
  //   //     data.chart = chartData.filter((c) => section === c.type);
  //   //     if (pathname.includes('local')) {
  //   //       const question = questions[questionIndex];
  //   //       data.chart = data.chart.filter((c) => question.categories.includes(c.name));
  //   //     }
  //   //   }
  //   // } else {
  //   //   data.chart = chartData;
  //   // }
  //   // if (data.chart.length) {
  //   //   setChartName(data.chart[0].name);
  //   //   setChartColor(data.chart[0].color);
  //   // }
  //   // setChartPartData(data.chart);
  // }, [pathname, chartData, questionIndex, questions, section]);

  // const getFirstQuestionUrl = () => {
  //   if (pathname.includes('/local/')) {
  //     const qIndex = questions.findIndex((q) => q.categories[0] === accordionItems[0].name);
  //     return `/calculators/local/${qIndex + 1}`;
  //   }
  //   if (pathname.includes('/global/')) {
  //     // TODO: переделать, из аккордеона надо получать дименшн, категори, индекс
  //     return `/calculators/global/1/1/1`;
  //   }
  //   return calculatorsList && calculatorsList.length ? calculatorsList[0].url : '/';
  // };

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        direction="column"
        className={styles.calculatorContainer}
        ref={calculatorContainer}
      >
        <Grid className={styles.breadcrumps}>
          TransparenTerra&nbsp;&nbsp;▷&nbsp;&nbsp;ESG&nbsp;&nbsp;▷&nbsp;&nbsp;
          <Link to="/">Calculator</Link>
          {accordionItems && accordionItems.length && (
            <span>
              &nbsp;&nbsp;▷&nbsp;&nbsp;
              {/* <Link to={getFirstQuestionUrl()}>{accordionItems[0].name}</Link> */}
              {accordionItems[0].name}
            </span>
          )}
        </Grid>
        <SelfAssessment />

        <Grid item container justifyContent="center" className={styles.content}>
          <ProgressCircle
            current={questionIndex + previousCount}
            count={questionCount}
            color={chartColor}
          />
          <div className={styles.chartArea}>
            <Chart
              chartData={chartPartData}
              hasDesktopFormat={false}
              onChartClick={() => openModal(chartName)}
              activeItem={activeSector}
            />
          </div>
          <Grid item container alignItems="center" className={styles.infoArea}>
            <AccordionGroup items={accordionItems} onAccordionClick={onAccordionSelect} />
          </Grid>
          <Routes>
            <Route exact path="/carbon" element={<Navigate to="1" />} />
            <Route
              exact
              path="/carbon/customCo2/:idx"
              element={<CarbonCalculator setQuestionIndex={setQuestionIndex} />}
            />
            <Route
              exact
              path="/carbon/:idx"
              element={
                <CarbonCalculator
                  setQuestionIndex={setQuestionIndex}
                  setQuestionCount={setQuestionCount}
                  setAccordionItems={setAccordionItems}
                  setChartPartData={setChartPartData}
                />
              }
            />
            <Route exact path="/local/:idx" element={<LocalCalculator />} />
            <Route
              exact
              path="/global/:dimUrl/:catUrl/:idxUrl"
              element={
                <GlobalCalculator
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  setQuestionCount={setQuestionCount}
                  setAccordionItems={setAccordionItems}
                  calculatorContainerRef={calculatorContainer}
                />
              }
            />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Grid>
      </Grid>
      {calculatorName === 'global' && (
        <SolutionsGlobalModal
          opened={isModalOpened}
          closeModalFunc={closeModal}
          activeItem={activeSector}
        />
      )}
      {calculatorName !== 'global' && (
        <SolutionsModal
          opened={isModalOpened}
          closeModalFunc={closeModal}
          activeItem={activeSector}
        />
      )}
    </>
  );
};

export default CalculatorsRouter;
