/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { Modal, Typography } from 'tt-ui-kit';
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, InputSearch, Switch } from 'tt-ui-lib/core';
import styles from './AllQuestionsModal.module.scss';
import { ReactComponent as AnsveredSvg } from '../../../../assets/icons/questionAnsvered.svg';

const CustomizedAccordion = styled(Accordion)(() => ({
  '.MuiAccordionDetails-root': {
    padding: 0,
    maxHeight: 'none',
  },
  '&.MuiAccordion-root': {
    borderRadius: '16px',
    width: '100%',
    padding: '16px',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
    marginBottom: '10px',
  },
  '&:before': {
    backgroundColor: 'white',
  },
  boxShadow: 'none',
}));

const AllQuestionsModal = ({
  questionId,
  carbonQuestions,
  answers,
  getItemNames,
  opened,
  closeModalFunc,
  getLabel,
}) => {
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState('');
  const [questionsOnly, setQuestionsOnly] = useState(false);
  const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const [filtered, setFiltered] = useState(null);

  const searchText = (value) => {
    setInputValue(value);
  };

  useEffect(() => {
    if (!opened) {
      setInputValue('');
    }
  }, [opened]);

  useEffect(() => {
    let index = 0;
    const newQuestionsAnswers = carbonQuestions.map((scope, sidx) => ({
      code: scope.code,
      description: scope.description,
      name: scope.name,
      questions: scope.categories.reduce(
        (acc, category) => [
          ...acc,
          ...category.questions.map((question, qind) => ({
            name: question.name,
            id: question.id,
            link: `/calculators/carbon/${++index}`,
            fields: [...question.fields],
            filled: answers[category.code]?.[question.id]?.some((answer) => answer.filled) ?? false,
            answers: answers[category.code]?.[question.id]
              ? [
                  ...answers[category.code][question.id].map((answer, indx) => ({
                    ...getItemNames(answer, question),
                    data: answer.data ?? '',
                    info: answer.info ?? '',
                    name: `Activity ${indx + 1}`,
                  })),
                ]
              : [],
          })),
        ],
        []
      ),
    }));
    setQuestionsAnswers(newQuestionsAnswers);
    return () => {
      setQuestionsAnswers([]);
    };
  }, [answers, carbonQuestions]);

  useEffect(() => {
    if (inputValue) {
      const newFiltered = questionsAnswers
        .map((scope) => ({
          ...scope,
          questions: scope.questions.filter((question) => {
            const names = question.answers.reduce(
              (acc, answer) => [
                ...acc,
                ...Object.keys(answer)
                  .filter((key) => !['name', 'link'].includes(key))
                  .map((key) => answer[key]),
              ],
              []
            );
            return (
              question.name.toLowerCase().includes(inputValue.toLowerCase()) ||
              names.some((name) => name?.toLowerCase().includes(inputValue.toLowerCase()))
            );
          }),
        }))
        .filter((scope) => scope.questions.length);
      setFiltered(newFiltered);
    }
    return () => {
      setFiltered(null);
    };
  }, [inputValue, questionsAnswers]);

  const onLinkClick = (link) => {
    closeModalFunc();
    navigate(link);
  };

  return (
    <Modal
      title="Preview all answers"
      onClose={closeModalFunc}
      open={opened}
      containerClassName={styles.modalRoot}
      className={styles.modal}
      fullScreenOnResponse
      closeOnlyByControls
    >
      <div className={styles.companyMainFrame}>
        <div className={styles.wrap}>
          <div className={styles.filterRow}>
            <InputSearch
              placeholder="Search"
              value={inputValue}
              className={styles.searchFilterInput}
              onChange={(e) => searchText(e.target.value)}
            />
            <Switch onChange={() => setQuestionsOnly((value) => !value)} value={!questionsOnly} />
            <Typography variant="text" component="div">
              My answers
            </Typography>
          </div>
          {(filtered ?? questionsAnswers).map((scope) => (
            <CustomizedAccordion key={scope.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ padding: '0px', margin: '0px' }}
              >
                <div className={styles.summary}>
                  <div className={styles.title}>{scope.name}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {scope.questions.map((question) => (
                  <>
                    <div key={question.id} className={styles.container}>
                      <div className={styles.sourceBlock}>
                        <div className={styles.questionBlock}>
                          <span>
                            <AnsveredSvg
                              className={clsx(
                                styles.qbuttonsvg,
                                { [styles.required]: !question?.filled },
                                { [styles.answered]: question?.filled }
                              )}
                            />
                          </span>
                          <Highlighter
                            highlightClassName={styles.hlight}
                            searchWords={[inputValue]}
                            textToHighlight={question.name}
                            unhighlightClassName={styles.questionTitle}
                          />
                        </div>
                        {!questionsOnly && (
                          <>
                            {!question.answers.length && <div>No answer available</div>}
                            {question.answers.length > 0 && (
                              <div className={styles.componentsContainer}>
                                {question.answers.map((answer, idx) => (
                                  <div className={styles.componentBlock} key={`${idx}`}>
                                    <div className={styles.componentNum}>{answer.name}:</div>
                                    {question?.fields && question?.fields.length && (
                                      <div className={styles.componentDetails}>
                                        {question.fields.map((field) => (
                                          <div key={answer[field]}>
                                            {getLabel(field)}:{' '}
                                            <Highlighter
                                              highlightClassName={styles.hlight}
                                              searchWords={[inputValue]}
                                              textToHighlight={answer[field]}
                                            />
                                          </div>
                                        ))}
                                        <div>
                                          Data:
                                          <Highlighter
                                            highlightClassName={styles.hlight}
                                            searchWords={[inputValue]}
                                            textToHighlight={answer.data}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                        {question.id === questionId ? (
                          <div className={styles.questionTitle}>Current</div>
                        ) : (
                          <Button type="icon" onClick={() => onLinkClick(question.link)}>
                            <LaunchIcon />
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </AccordionDetails>
            </CustomizedAccordion>
          ))}
        </div>
      </div>
    </Modal>
  );
};

AllQuestionsModal.propTypes = {
  questionId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  carbonQuestions: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  answers: PropTypes.object,
  getItemNames: PropTypes.func,
  opened: PropTypes.bool,
  closeModalFunc: PropTypes.func,
  getLabel: PropTypes.func,
};

export default AllQuestionsModal;
