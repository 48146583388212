import { axiosClient, axiosSSOConfigPrivate } from '../../rest-client';
import { API_CALC_GET_FACTORS, API_CALC_GET_QUESTIONS, API_CALC_GET_CONFIG } from './urls';

export const getFactors = async () =>
  axiosClient.get(API_CALC_GET_FACTORS, axiosSSOConfigPrivate());

export const getCalcQuestions = async (params) =>
  axiosClient.get(API_CALC_GET_QUESTIONS, axiosSSOConfigPrivate({}, { params: params }));

export const getConfig = async (params) =>
  axiosClient.get(API_CALC_GET_CONFIG, axiosSSOConfigPrivate({}, { params: params }));
