import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid } from '@mui/material';
import Chart from '../chart/Chart';
import AccordionGroup from '../accordionGroup/AccordionGroup';
import { CalculatorsContext } from '../../context';

import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg';
import styles from './SolutionsModal.module.scss';
import { Solutions, CalculatorDescriptions } from '../../constants';

const SolutionsModal = ({ activeItem, opened, closeModalFunc }) => {
  const { chartData, lastVisitedIndex } = useContext(CalculatorsContext);

  const [items, setItems] = useState([]);
  const [active, setActive] = useState(activeItem);

  const onCloseClick = () => {
    setActive(null);
    closeModalFunc();
  };

  const onChartSectorClick = (name) => {
    // const accordionItems = [...items];
    // const activeParent = accordionItems.findIndex((v) => v.expanded);
    // if (activeParent !== -1) {
    //   accordionItems[activeParent].expanded = false;
    //   const activeChild = accordionItems[activeParent].children.findIndex((v) => v.expanded);
    //   if (activeChild !== -1) {
    //     accordionItems[activeParent].children[activeChild].expanded = false;
    //   }
    // }
    // if (name) {
    //   const newActiveParent = accordionItems.findIndex((v) =>
    //     v.children.some((c) => c.name === name)
    //   );
    //   const newActiveChild = accordionItems[newActiveParent].children.findIndex(
    //     (v) => v.name === name
    //   );
    //   if (newActiveParent !== -1) {
    //     accordionItems[newActiveParent].expanded = true;
    //     if (newActiveChild !== -1)
    //       accordionItems[newActiveParent].children[newActiveChild].expanded = true;
    //   }
    // }
    // setItems(accordionItems);
  };

  const onAccordionClick = (selected, name) => {
    const accordionItems = [...items];

    const collapseChildElement = (pIndex) => {
      const cIndex = accordionItems[pIndex].children.findIndex((v) => v.expanded);

      if (cIndex !== -1) {
        accordionItems[pIndex].children[cIndex].expanded = false;
      }
    };

    if (selected) {
      const newActiveParent = accordionItems.findIndex((v) => v.name === name);

      if (newActiveParent !== -1) {
        const activeParent = accordionItems.findIndex((v) => v.expanded);

        if (activeParent !== -1) {
          accordionItems[activeParent].expanded = false;
          collapseChildElement(activeParent);
        }
        accordionItems[newActiveParent].expanded = true;
        setActive(null);
      } else {
        const activeParent = accordionItems.findIndex((v) => v.expanded);
        const activeChild = accordionItems[activeParent].children.findIndex((v) => v.expanded);
        const newActiveChild = accordionItems[activeParent].children.findIndex(
          (v) => v.name === name
        );

        if (activeChild !== -1) {
          accordionItems[activeParent].children[activeChild].expanded = false;
        }
        accordionItems[activeParent].children[newActiveChild].expanded = true;
        setActive(name);
      }
    } else {
      const activeParent = accordionItems.findIndex((v) => v.expanded);

      if (activeParent !== -1) {
        if (accordionItems[activeParent].name === name) {
          accordionItems[activeParent].expanded = false;
        }
        collapseChildElement(activeParent);
      }
      setActive(null);
    }

    setItems(accordionItems);
  };

  const getAccordionItems = () => {
    const accordionItems = Solutions.map((solution) => ({
      name: solution.name,
      expanded: false,
      children: solution.children.filter(
        (child) =>
          chartData.some((item) => item.name === child.name) &&
          // убрать packaging calculator, потому что он часть product
          child.name !== 'Packaging Calculator'
      ),
    })).filter((solution) => solution.children.length > 0);

    accordionItems.forEach((item, i) => {
      item.children.forEach((child, j) => {
        accordionItems[i].children[j].content = CalculatorDescriptions[child.name];
        accordionItems[i].children[j].expanded = child.name === activeItem;
      });

      if (item.children.some((child) => child.expanded)) {
        /* eslint-disable-next-line no-param-reassign */
        item.expanded = true;
      }
    });
    return accordionItems;
  };

  useEffect(() => {
    setItems(getAccordionItems());
  }, [opened]);

  useEffect(() => {
    setActive(activeItem);
  }, [activeItem]);

  return (
    <>
      {opened && (
        <div className={styles.modal}>
          <Button variant="text" className={styles.closeBtn} onClick={onCloseClick}>
            <CloseSvg className={styles.closeSvg} />
          </Button>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            className={styles.content}
          >
            <div className={styles.chartArea}>
              <Chart
                chartData={chartData}
                activeItem={active}
                onSectorClick={onChartSectorClick}
                hasDesktopFormat
                lastIndex={lastVisitedIndex}
              />
            </div>
            <div className={styles.accordionArea}>
              <AccordionGroup items={items} onAccordionClick={onAccordionClick} />
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

SolutionsModal.propTypes = {
  activeItem: PropTypes.string,
  opened: PropTypes.bool,
  closeModalFunc: PropTypes.func,
};

SolutionsModal.defaultProps = {
  activeItem: '',
  opened: false,
  closeModalFunc: () => {},
};

export default SolutionsModal;
