import { axiosClient, axiosSSOConfigPrivate } from '../../rest-client';
import {
  API_FILES_GET_URL,
  API_FILES_SET_URL,
  API_FILES_ADD_URL,
  API_FILES_DELETE_URL,
  API_FILES_GET_UPLOAD_URL,
} from './urls';

export const uploadFileToAWS = async (url, file) =>
  axiosClient.put(url, file, {
    headers: {
      'Content-Type': file.type,
      'Access-Control-Allow-Origin': '*',
    },
  });

export const getFiles = async (params, headers) =>
  axiosClient.get(API_FILES_GET_URL, axiosSSOConfigPrivate(headers, { params: params }));

export const getUploadUrl = async (params, headers) =>
  axiosClient.get(API_FILES_GET_UPLOAD_URL, axiosSSOConfigPrivate(headers, { params: params }));

export const setFiles = async (params, headers) =>
  axiosClient.post(API_FILES_SET_URL, params, axiosSSOConfigPrivate(headers));

export const deleteFiles = async (params, headers) =>
  axiosClient.post(API_FILES_DELETE_URL, params, axiosSSOConfigPrivate(headers));

export const addFiles = async (params, headers) =>
  axiosClient.post(API_FILES_ADD_URL, params, axiosSSOConfigPrivate(headers));
