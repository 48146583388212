import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { ConfigProvider } from 'tt-ui-lib/core';
import DisconnectHandlerWrapper from './utils/disconnectHandler/disconnectHandler';

import { AppState, ErrorState, CalculatorsState, DraftState } from './context';
import appTheme from './mui-theme';
import apolloClient from './api/apollo/apollo-client';

import App from './App';
import './index.css';
import 'tt-ui-kit/dist/index.css';
import 'tt-ui-lib/core/style.css';

const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider theme={appTheme}>
      <ConfigProvider>
        <ErrorState>
          <ApolloProvider client={apolloClient}>
            <AppState>
              <DraftState>
                <CalculatorsState>
                  <DisconnectHandlerWrapper />
                  <App />
                </CalculatorsState>
              </DraftState>
            </AppState>
          </ApolloProvider>
        </ErrorState>
      </ConfigProvider>
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
