/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useContext, useReducer } from 'react';

import {
  SET_USER_DATA,
  SET_NEW_COMPANY_DATA,
  SET_USER_COMPANIES,
  SET_COUNTRIES,
  SET_INDUSTRIAL_GROUPS,
  SET_SUBINDUSTRIES,
  SET_LEGAL_TYPES,
  SET_DRAFT_ID,
  SET_DRAFT,
  SET_FACTORS,
  SET_COMPANY_ID,
} from '../types';

import AppContext from '../App/appContext';
import DraftContext from './draftContext';
import draftReducer from './draftReducer';

const DraftState = ({ children }) => {
  const initialUser = {
    // first step
    countryId: '',
    companyId: '',
    establishedDate: new Date(),
    legalTypeId: '',
    address1: '',
    address2: '',
    zipCode: '',
    industrialGroupId: '',
    subIndustryId: '',
    // second step
    annualTurnover: '',
    netProfit: '',
    yearTaxes: '',
    numberUniqueProducts: '',
    annualProductivityEachPosition: '',
    employeesNumber: '',
    periodReportDate: new Date(),
  };
  const initialState = {
    user: {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      ...initialUser,
    },
    companies: {},
    countries: {},
    industrialGroups: {},
    subIndustries: {},
    legalTypes: {},
    draftId: '',
    draft: [],
    companyId: null,
  };

  const [state, dispatch] = useReducer(draftReducer, initialState);
  const { setItemInStorage, removeItemFromStorage } = useContext(AppContext);

  const setCompanyId = (companyId) => {
    dispatch({
      type: SET_COMPANY_ID,
      payload: companyId,
    });
  };

  const changeUserData = (user) => {
    dispatch({
      type: SET_USER_DATA,
      payload: { ...user },
    });
  };

  const GetCompaniesWithoutTemp = (company) =>
    Object.keys(state.companies).reduce((result, key) => {
      const res = result;
      if (key !== 'newUserCompanyId') {
        res[key] = state.companies[key];
      }

      return {
        ...res,
        [company.id]: {
          ...company,
        },
      };
    }, {});

  const changeNewCompanyData = (company = {}) => {
    const companies = GetCompaniesWithoutTemp(company);
    dispatch({
      type: SET_NEW_COMPANY_DATA,
      payload: companies,
    });
  };

  const emptyUserData = () => {
    const newUser = {
      ...state.user,
      ...initialUser,
    };

    dispatch({
      type: SET_USER_DATA,
      payload: newUser,
    });
  };

  const changeUserCompanies = (companies = []) => {
    if (!companies) return;
    const companiesObj = companies.reduce(
      (res, company) => ({
        ...res,
        [company.id]: {
          ...company,
          countryId: company.addressLocation != null ? company.addressLocation.countryId : '',
          address1: company.addressLocation != null ? company.addressLocation.address : '',
          zipCode: company.addressLocation != null ? company.addressLocation.zipCode : '',
          address2: company.address2 != null ? company.address2 : '',
        },
      }),
      {}
    );

    dispatch({
      type: SET_USER_COMPANIES,
      payload: companiesObj,
    });
  };

  const changeCountriesList = (countries = []) => {
    const countriesObj = countries.reduce(
      (res, country) => ({ ...res, [country.id]: country }),
      {}
    );

    dispatch({
      type: SET_COUNTRIES,
      payload: countriesObj,
    });
  };

  const setFactors = (factors = []) => {
    // const factorsObj = factors.reduce(
    //   (res, factor) => ({ ...res, [factor.name]: factor }),
    //   {},
    // );

    dispatch({
      type: SET_FACTORS,
      payload: factors,
    });
  };

  const changeIndustrialGroups = (industrialGroups = []) => {
    const indGroupsObj = industrialGroups.reduce(
      (res, group) => ({ ...res, [group.id]: group }),
      {}
    );

    dispatch({
      type: SET_INDUSTRIAL_GROUPS,
      payload: indGroupsObj,
    });
  };

  const changeSubIndustries = (subIndustries = []) => {
    const subIndustriesObj = subIndustries.reduce(
      (res, group) => ({ ...res, [group.id]: group }),
      {}
    );

    dispatch({
      type: SET_SUBINDUSTRIES,
      payload: subIndustriesObj,
    });
  };

  const changeLegalTypes = (legalTypes = []) => {
    const legalTypesObj = legalTypes.reduce((res, type) => ({ ...res, [type.id]: type }), {});

    dispatch({
      type: SET_LEGAL_TYPES,
      payload: legalTypesObj,
    });
  };

  const setDraftId = (id) => {
    if (id) setItemInStorage('SELECTED_DRAFT_ID', id);
    else removeItemFromStorage('SELECTED_DRAFT_ID');
    dispatch({
      type: SET_DRAFT_ID,
      payload: id,
    });
  };

  const setDraft = (draft) => {
    dispatch({
      type: SET_DRAFT,
      payload: { ...draft },
    });
  };

  return (
    <DraftContext.Provider
      value={{
        user: state.user,
        companies: state.companies,
        countries: state.countries,
        industrialGroups: state.industrialGroups,
        subIndustries: state.subIndustries,
        legalTypes: state.legalTypes,
        draftId: state.draftId,
        draft: state.draft,
        companyId: state.companyId,
        factors: state.factors,
        setFactors,
        setCompanyId,
        changeUserData,
        changeNewCompanyData,
        emptyUserData,
        changeUserCompanies,
        changeCountriesList,
        changeIndustrialGroups,
        changeSubIndustries,
        changeLegalTypes,
        setDraftId,
        setDraft,
      }}
    >
      {children}
    </DraftContext.Provider>
  );
};

DraftState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DraftState;
