/* eslint-disable */

import clsx from 'clsx';
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { ClickAwayListener } from '@mui/material';
import { DraftContext } from '../../context';
import { Menu, AddMenu, UserMenu } from '../../constants';

import styles from './Header.module.scss';

import { ReactComponent as LogoSvg } from '../../assets/icons/logo-transparenterra-big.svg';
import { ReactComponent as MenuSvg } from '../../assets/icons/menu.svg';
// import { ReactComponent as BottomArrowSvg } from "../../assets/icons/bottomArrow.svg";

const Header = () => {
  const [isLess768, setIsLess768] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => setIsLess768(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const [headerMenuList, setHeaderMenuList] = useState([]);
  const [userMenuList, setUserMenuList] = useState([]);
  const [mobileMenuList, setMobileMenuList] = useState([]);
  const [isNotIndiv, setIsNotIndiv] = useState(false);
  const { user, draftId } = useContext(DraftContext);

  useEffect(() => {
    const isNotInd = user.roleName !== 'individual' && user.roleName !== 'digital';
    setIsNotIndiv(isNotInd);
    const topMenu = [...Menu, ...AddMenu];
    const newHMenu = [...topMenu, { name: 'Sign in/up', url: '/login' }];
    setHeaderMenuList(user && user.id ? topMenu : newHMenu);
  }, [user]);

  useEffect(() => {
    const getUrl = (item) => {
      if (item.disabled) return '';
      switch (item.name) {
        case 'Profile':
          return `${item.url}/${user.id}`;
        case 'Continue editing':
          return `${item.url}/${draftId}`;
        default:
          return item.url;
      }
    };
    const leftMenu = UserMenu.sort((a, b) => a.order - b.order);

    const newUMenu = leftMenu
      .filter((item) => isNotIndiv || !item.notForIndiv)
      .map((item) => ({
        ...item,
        url: getUrl(item),
        disabled: item.disabled || (item.name === 'Continue editing' && !draftId),
      }));
    setUserMenuList(user && user.id ? newUMenu : []);
  }, [isNotIndiv, draftId]);

  useEffect(() => {
    const newMobMenu = [...headerMenuList, { name: 'hr' }, ...userMenuList];
    setMobileMenuList(newMobMenu);
  }, [headerMenuList, userMenuList]);

  return (
    <header>
      <div className={styles.headerContent}>
        <Link to="/dashboard">
          <LogoSvg className={styles.logo} />
        </Link>

        {!isLess768 && (
          <>
            <nav className={clsx(styles.menu, isMenuOpened && styles.opened)}>
              <ul className={styles.menuList}>
                {headerMenuList.map((item) => (
                  <li
                    key={item.name}
                    className={clsx(styles.menuItem, item.disabled && styles.disabled)}
                  >
                    <Link to={item.url || ''} name={item.name}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            {user && user.id && (
              <ClickAwayListener onClickAway={() => setIsMenuOpened(false)}>
                <div className={styles.wideMenu}>
                  <div
                    className={styles.avatarContainer}
                    onClick={() => setIsMenuOpened((value) => !value)}
                  >
                    <Avatar
                      className={styles.avatar}
                      alt={`${user.firstName} ${user.lastName}`}
                      src={user.avatar}
                    />
                    <div className={styles.userMenuBtn} />
                  </div>
                  <ul className={clsx(styles.userMenu, isMenuOpened && styles.opened)}>
                    {userMenuList.map((item, idx) =>
                      item.name === 'hr' ? (
                        <hr key={`${item.name}${idx}`} />
                      ) : (
                        <li
                          key={item.name}
                          className={(item.disabled && styles.disabled) || ''}
                          onClick={() => setIsMenuOpened((value) => !value)}
                        >
                          <a
                            name={item.name}
                            href={item.url}
                            className={clsx(item.name === 'Log out' && styles.menuRed)}
                          >
                            {item.name}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </ClickAwayListener>
            )}
          </>
        )}
        {isLess768 && (
          <ClickAwayListener onClickAway={() => setIsMenuOpened(false)}>
            <div>
              <button
                className={styles.menuButton}
                onClick={() => setIsMenuOpened((value) => !value)}
              >
                <MenuSvg />
              </button>
              <nav className={clsx(styles.menu, isMenuOpened && styles.opened)}>
                <div className={styles.mobileMenu}>
                  {user && (
                    <div>
                      <div className={styles.headerUserInfo}>
                        <span onClick={() => setIsMenuOpened(false)} className={styles.arrow} />
                        <div>
                          <p
                            className={styles.mobileUserName}
                          >{`${user.firstName} ${user.lastName}`}</p>
                          <p className={styles.mobileUserRole}>{user.roleName}</p>
                        </div>
                        <div>
                          <Avatar
                            className={styles.avatar}
                            alt={`${user.firstName} ${user.lastName}`}
                            src={user.avatar}
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  )}
                  <ul className={clsx(styles.userMenu, isMenuOpened && styles.opened)}>
                    {mobileMenuList.map((item, idx) =>
                      item.name === 'hr' ? (
                        <hr key={`${item.name}${idx}`} />
                      ) : (
                        <li
                          key={item.name}
                          className={(item.disabled && styles.disabled) || ''}
                          onClick={() => setIsMenuOpened((value) => !value)}
                        >
                          <a name={item.name} href={item.url}>
                            {item.name}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </ClickAwayListener>
        )}
        <div className={clsx(styles.menuBlur, isMenuOpened && styles.opened)} />
      </div>
    </header>
  );
};

export default Header;
