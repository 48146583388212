/* eslint-disable */

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@mui/material';
import UiDatePicker from '../../components/uiDatePicker/UiDatePicker';

import styles from './RegistrationForm.module.scss';
import UiTextField from '../../components/uiTextField/UiTextField';
import FileUploader from '../../components/fileUploader/FileUploader';
import MuiSelect from '../../components/muiSelect/MuiSelect';

const RegistrationCarbon = ({
  onChange,
  onChangeDate,
  openDisclaimer,
  data,
  numberFilter,
  reportYear,
  dateDisable,
  factorList,
}) => (
  <>
    <Button
      className={styles.disclaimerBtn}
      size="small"
      color="lightBlue"
      onClick={openDisclaimer}
    >
      Basic details*
    </Button>
    <UiTextField
      className={clsx(styles.input, styles.wide)}
      label="Number of unique product *"
      name="numberUniqueProducts"
      value={((data && data.numberUniqueProducts) ?? '').toString()}
      onChange={onChange}
      filter={numberFilter}
      maxNumber={2147483647}
    />
    <UiTextField
      className={clsx(styles.input, styles.normal)}
      label="The number of employeesNumber *"
      name="employeesNumber"
      value={((data && data.employeesNumber) ?? '').toString()}
      onChange={onChange}
      filter={numberFilter}
      maxNumber={2147483647}
    />
    <UiTextField
      className={clsx(styles.input, styles.normal)}
      label="Annual annualProductivityEachPosition for each position *"
      name="annualProductivityEachPosition"
      value={((data && data.annualProductivityEachPosition) ?? '').toString()}
      onChange={onChange}
      filter={numberFilter}
      maxNumber={2147483647}
    />
    <UiDatePicker
      label="Reporting year *"
      name="periodReportDate"
      onChange={onChangeDate}
      value={reportYear ? new Date(reportYear, 1, 1) : null}
      disabled={dateDisable}
      minDate={new Date(2000, 1, 1)}
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
      className={clsx(styles.input, styles.wide)}
      onlyYear
      required
    />
    <MuiSelect
      className={clsx(styles.input, styles.wide)}
      label="Coversion factors"
      name="ukGhgCollectionId"
      value={data.ukGhgCollectionId ?? ''}
      onChange={onChange}
      renderValue={(value) => factorList.find((item) => item.id === value)?.name}
      items={factorList?.map((item) => item.id) ?? []}
    />
    <div>
      <FileUploader model="carbon" questionCode="registration" disabled={!data.id} />
    </div>
  </>
);

RegistrationCarbon.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  openDisclaimer: PropTypes.func.isRequired,
  numberFilter: PropTypes.instanceOf(RegExp),
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  years: PropTypes.array,
  reportYear: PropTypes.number,
  dateDisable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  factorList: PropTypes.array,
};

export default RegistrationCarbon;
