/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './QuestionButton.module.scss';
import { ReactComponent as ArrowSvg } from '../../assets/icons/questionArrow.svg';
import { ReactComponent as AnsveredSvg } from '../../assets/icons/questionAnsvered.svg';
import { ReactComponent as SaveSvg } from '../../assets/icons/questionSave.svg';

const QuestionButton = ({ action, type, label, isAnsvered }) => (
  <>
    {type === 'label' && ( // TODO разблокировать когда будет доработан функционал
      <button
        className={clsx(styles.QuestionButton, {
          [styles.transform]: !type === 'label',
        })}
        onClick={action}
      >
        {type === 'isAnsvered' && (
          <AnsveredSvg
            className={clsx(
              styles.qbuttonsvg,
              { [styles.required]: !isAnsvered },
              { [styles.answered]: isAnsvered }
            )}
          />
        )}
        {type === 'redirect' && <ArrowSvg className={clsx(styles.qbuttonsvg)} />}
        {type === 'save' && <SaveSvg className={clsx(styles.qbuttonsvg)} />}
        {label && (
          <label
            className={clsx(styles.qbuttonlabel, {
              [clsx({ [styles.required]: !isAnsvered }, { [styles.answered]: isAnsvered })]:
                isAnsvered != null,
            })}
          >
            {label}
          </label>
        )}
      </button>
    )}
  </>
);

QuestionButton.propTypes = {
  action: PropTypes.func,
  type: PropTypes.string,
  label: PropTypes.string,
  isAnsvered: PropTypes.bool,
};

export default QuestionButton;
