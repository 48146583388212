/* eslint-disable */

import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const TooltipState = ({ tooltipText, iconWidth, iconHeight, Icon }) => (
  <Tooltip
    slotProps={{
      tooltip: {
        sx: {
          fontFamily: 'Montserrat',
          fontSize: '12px',
          padding: '12px 20px',
          border: 'none',
          borderRadius: '12px',
          color: '#FFFFFF',
          backgroundColor: '#999999',
        },
      },
    }}
    title={tooltipText}
    placement="top"
    describeChild
  >
    <span
      style={{
        display: 'block',
        width: iconWidth,
        height: iconHeight,
        marginRight: 12,
      }}
    >
      <Icon style={{ width: iconWidth, height: iconHeight, flexShrink: 0 }} />
    </span>
  </Tooltip>
);

TooltipState.propTypes = {
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  tooltipText: PropTypes.string.isRequired,
  Icon: PropTypes.any.isRequired,
};

TooltipState.defaultProps = {
  iconWidth: 20,
  iconHeight: 20,
};

export default TooltipState;
