import clsx from 'clsx';
import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import LazyImage from '../../components/lazyImage/LazyImage';

import styles from './OffsetCertificates.module.scss';
import Header from '../../components/header/Header';

const OffsetCertificates = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <main>
        <div className={styles.container}>
          <LazyImage
            className={clsx(styles.lazyImage, 'lazy')}
            bg="offsetCertificatesBackground.jpg"
          />

          <div className={styles.outerCircle} />
          <div className={styles.innerCircle} />

          <div className={styles.info}>
            <h1>CO2 Offset Credits</h1>
            <div className={styles.textContainer}>
              <h2>Prudent and responsible business practices</h2>
              <p>
                Continued emissions of greenhouse gasses can lead to highly undesirable long-term
                climate change on a global scale and severe, profound and irreversible consequences
                for people and ecosystems.
              </p>
              <br />
              <p>
                Reducing carbon dioxide emissions mitigates climate change, supports biodiversity,
                and improves public health. By reducing carbon emissions, we preserve clean air,
                water, food, and a place to live for our own and future generations.
              </p>
              <h2>Why is it so important for companies to reduce their carbon footprint?</h2>
              <p>
                Government institutions, investment funds, human resource agencies, analysts,
                journalists, and many other organizations and individuals in various fields consider
                a company&apos;s ESG score when making decisions about investments, collaborations,
                hiring, etc.
              </p>
              <br />
              <p>
                An ESG score identifies a company&apos;s reputational risks and opportunities. A
                solid ESG program can provide access to large pools of capital, build a stronger
                corporate brand, and promote sustainable, long-term growth that benefits the company
                and investors.
              </p>
              <h2>Lower operating costs</h2>
              <p>
                A number of companies in various sectors have achieved good results by following a
                strategy of continuous improvement and using the best available technologies. This
                is the foundation of the ESG approach to business. Many companies are seeing
                significant efficiency gains after implementing loss prevention and downtime
                programs, upgrading their facilities, and reusing production waste.
              </p>
              <h2>High investment attractiveness</h2>
              <p>
                Investors prefer to invest in ethical and sustainable companies. Investment
                companies are incorporating ESG ratings into their portfolio risk assessments,
                indicating that capital will continue to flow to companies with strong ESG programs
                and practices.
              </p>
              <h2>A strong brand</h2>
              <p>
                Leaders strengthen their corporate brand when they improve their company&apos;s
                environmental and labor impacts, increase diversity in their teams, and support
                their communities. A good reputation means high confidence in the business
                environment and better performance. employeesNumber, consumers and investors pay
                close attention to good company numbers and reward the best-performing companies
                with loyalty.
              </p>
              <h2>Reducing the risk of regulatory sanctions</h2>
              <p>
                Strengthening the external ESG value proposition allows companies to gain greater
                strategic freedom by reducing regulatory pressure. The more a company focuses on its
                social and environmental impact, the less risk it faces from regulators. This leads
                to business stability and lower reputational risk.
              </p>
              <h2>A company you want to work for</h2>
              <p>
                Companies with strong ESG values attract and retain the best employeesNumber.
                It&apos;s important to people that the companies they work for and the businesses
                they support hold values that align with their own. The alignment of values and
                loyalty strengthens a company&apos;s brand and improves its performance.
              </p>
            </div>
            <Button form="rounded" variant="contained" onClick={() => navigate('/carbon_credits')}>
              Carbon Credits
            </Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default OffsetCertificates;
