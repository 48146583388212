import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { DraftContext } from '../../context';

const LoadedDraftRoute = () => {
  const { draftId } = useContext(DraftContext);
  if (!draftId) return <></>;
  return <Outlet />;
};

export default LoadedDraftRoute;
