import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import Header from './components/header/Header';
import Loading from './components/loading/Loading';
import Start from './views/start/Start';
import NotFound from './views/notFound/NotFound';
import Finish from './views/finish/Finish';
import NewAssessment from './views/ttAssessment/NewAssessment';
import EditAssessment from './views/ttAssessment/EditAssessment';
import Registration from './views/registration/Registration';
import CalculatorsRouter from './views/calculators/CalculatorsRouter/CalculatorsRouter';
import ProtectedRoute from './views/ttAssessment/ProtectedRoute';
import LoadedDraftRoute from './views/ttAssessment/LoadedDraftRoute';

import { AppContext, CalculatorsContext, DraftContext } from './context';

import {
  GET_COMPANIES,
  GET_COUNTRIES,
  GET_INDUSTIAL_GROUPS,
  GET_SUBINDUSTRIES,
  GET_LEGAL_TYPES,
} from './api/apollo/api';

import { getFactors, getConfig, getDraftById } from './api/rest/list';

const initialLoaderFlags = {
  companiesData: false,
  countriesData: false,
  indGroupsData: false,
  subIndData: false,
  legalTypesData: false,
  factors: false,
};

const AppRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    changeUserCompanies,
    changeCountriesList,
    changeIndustrialGroups,
    changeSubIndustries,
    changeLegalTypes,
    setCompanyId,
    setDraftId,
    setDraft,
    setFactors,
    user,
    draftId,
    draft,
  } = useContext(DraftContext);

  const { calculatorName, setConfig } = useContext(CalculatorsContext);

  const { loaded, changeAppLoading, changeAppLoaded, clearStorage, getItemFromStorage } =
    useContext(AppContext);

  const [loadingNumber, setLoadingNumber] = useState(initialLoaderFlags);
  const [startLoading, setStartLoading] = useState(true);
  const [tryRestore, setTryRestore] = useState(false);

  useEffect(() => {
    if (
      location.pathname.includes('/startCalculator') ||
      location.pathname.includes('/editCalculator')
    ) {
      clearStorage();
    } else {
      setTryRestore(true);
    }
  }, []);

  useEffect(() => {
    changeAppLoading(0);
    changeAppLoaded(false);
    setLoadingNumber(initialLoaderFlags);
    setStartLoading(true);
  }, []);

  const increaseLoading = (respName) => {
    setLoadingNumber((obj) => ({
      ...obj,
      [respName]: true,
    }));
  };

  // const [getQuestions, { data: questionsData }] = useLazyQuery(GET_CALCULATOR_QUESTIONS);

  // market data
  const [getCompaniesData, { data: companiesData }] = useLazyQuery(GET_COMPANIES);
  const [getCountriesData, { data: countriesData }] = useLazyQuery(GET_COUNTRIES);
  const [getIndGroupsData, { data: indGroupsData }] = useLazyQuery(GET_INDUSTIAL_GROUPS);
  const [setSubIndData, { data: subIndData }] = useLazyQuery(GET_SUBINDUSTRIES);
  const [getLegalTypesData, { data: legalTypesData }] = useLazyQuery(GET_LEGAL_TYPES);

  // const [getGlobalQuestions, { data: globalQuestions }] = useLazyQuery(GET_GLOBAL_QUESTIONS_DATA);

  const getFactorsList = () => {
    getFactors().then((res) => {
      const { data, success } = res.data;
      if (success) setFactors(data);
      increaseLoading('factors');
    });
  };

  useEffect(() => {
    if (!startLoading || !user) return;
    setStartLoading(false);
    getCountriesData();
    getIndGroupsData();
    setSubIndData();
    getLegalTypesData();
    getFactorsList();
  }, [startLoading, user]);

  useEffect(() => {
    if (!user?.id) return;
    getCompaniesData({ variables: { id: user.id } });
  }, [user]);

  useEffect(() => {
    const keys = Object.keys(loadingNumber);
    const count = keys.reduce((res, k) => res + (loadingNumber[k] ? 1 : 0), 0);
    changeAppLoading(count / keys.length);
  }, [loadingNumber]);

  useEffect(() => {
    // if (!tryRestore) return;
    if (!draftId) setDraft(null);
    else {
      getDraftById({ id: draftId }).then((res) => {
        const { data, success } = res.data;
        if (success) {
          setDraft(data);
          setCompanyId(data.companyId);
        } else {
          navigate('/drafts');
        }
        setTryRestore(false);
      });
    }
  }, [draftId, tryRestore]);

  useEffect(() => {
    if (!draft?.type) return;
    getConfig({ type: draft.type }).then((res) => {
      const { data, success } = res.data;
      if (success) {
        setConfig(data);
      }
    });
  }, [draft]);

  useEffect(() => {
    if (!tryRestore) return;
    const newDraftId = getItemFromStorage('SELECTED_DRAFT_ID');
    setDraftId(newDraftId);
  }, [tryRestore]);

  useEffect(() => {
    if (companiesData) {
      increaseLoading('companiesData');
      changeUserCompanies(companiesData.showAllCompaniesByUserId);
    }
  }, [companiesData]);

  useEffect(() => {
    if (countriesData) {
      increaseLoading('countriesData');
      changeCountriesList(countriesData.showCountries);
    }
  }, [countriesData]);

  useEffect(() => {
    if (indGroupsData) {
      increaseLoading('indGroupsData');
      changeIndustrialGroups(indGroupsData.showIndustryGroups);
    }
  }, [indGroupsData]);

  useEffect(() => {
    if (subIndData) {
      increaseLoading('subIndData');
      changeSubIndustries(subIndData.showSubIndustries);
    }
  }, [subIndData]);

  useEffect(() => {
    if (legalTypesData) {
      increaseLoading('legalTypesData');
      changeLegalTypes(legalTypesData.showCompanyLegalTypes);
    }
  }, [legalTypesData]);

  return (
    <>
      {loaded && (
        <>
          <Header />
          <main>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route exact path="/editCalculator/:draftId" element={<EditAssessment />} />
                <Route
                  exact
                  path="/startCalculator/:calcName/:companyId"
                  element={<NewAssessment />}
                />
                <Route element={<LoadedDraftRoute />}>
                  <Route exact path="/start" element={<Start />} />
                  <Route exact path="/registration" element={<Registration />} />
                  <Route path="/calculators/*" element={<CalculatorsRouter />} />
                  <Route exact path="/finish/*" element={<Finish />} />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </>
      )}
      <Loading />
    </>
  );
};

export default AppRouter;
